<template>
  <div class="min-h-screen my-10 mevcut-container">
    <div class="mb-10 text-xl uppercase">{{ t('terms_of_use') }}</div>
    <div v-if="terms" style="line-height: 2rem" v-html="terms"></div>
  </div>
</template>

<script setup lang="ts">
const terms = await useGroupSettings(11, 'terms')
const { url } = useDomainHost()
const { t } = useI18n()

useServerSeoMeta(
  useOgMeta(
    url + '/terms',
    t('terms_of_use'),
    t('mevcut_description'),
    url + '/images/mevcut.webp'
  )
)

useHead({
  title: t('terms_of_use'),
  link: [useSeoCanonical(url + '/terms')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('terms_of_use'), url + '/terms')
    ])
  ]
})
</script>
